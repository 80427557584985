* {
  box-sizing: border-box; 
  --base-300: #ffffff;
  --base-400: #f5f7f9;
  --base-500: #d7d8e6;
  --base-600: #d9d8d4;
  --base-700: #25252b;
  --base-800: #171717;
  --text-200: #b8bcd1;
  --text-300: #797e99;
  --text-400: #565a75;
  --text-500: #323346;
  /* --accent-300: #ffe1d8;
  --accent-400: #ff7348;
  --accent-500: #ff5724; */
  --accent-300: #b5ffe9;
  --accent-400: #17E891;
  --accent-500: #6717e8;
  --shadow-500: rgba(42, 41, 124, .15);
  --shadow-800: rgba(19, 19, 26, 0.5);
  --shadow-900: rgba(0, 0, 0, 0.25);
}

html {
  scroll-behavior: smooth;
  scroll-snap-type: y mandatory;
}

button {
  padding: 0;
  cursor: pointer;
  border: none;
  background: transparent;
}

textarea {
  padding: 0;
}

a {
  text-decoration: none;
  color: inherit;
  transition: color .5s;
}

a:hover {
  color: var(--accent-500);
}

b {
  font-weight: 600;
  color: var(--accent-400);
}

ol, ul {
  margin: 0;
}

p, h1, h2, h3, h4, h5 {
  margin: 0;
}

h1, h2 {
  color: var(--text-500);
  /* font-family: "Archivo Black", sans-serif; */
  font-family: 'Montserrat';
}

h3, h4, h5 {
  color: var(--text-400);
  font-family: 'Montserrat';
}

body {
  position: relative;
  margin: 0;
  color: var(--text-300);
  background-color: var(--base-300);
  overflow-x: hidden;
  font-family: 'Inter', -apple-system, system-ui, sans-serif;
} 

@font-face {
  font-family: 'Consola';
  src: url('./fonts/Consola.ttf');
}

@font-face {
  font-family: 'ArchitectsDaughter';
  src: url('./fonts/ArchitectsDaughter.ttf');
}

@font-face {
  font-family: 'Comfortaa';
  src: url('./fonts/Comfortaa.ttf');
}

@font-face {
  font-family: 'Montserrat';
  src: url('./fonts/Montserrat.ttf');
}

@font-face {
  font-family: 'Inter';
  src: url('./fonts/Inter.ttf');
}

@keyframes waiting{
  0% {
    opacity: 0;
  }
  49% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}