.about {
    overflow: hidden;
    position: relative;
    scroll-snap-align: center;
    position: relative;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.about-container {
    /* border: 1px solid var(--base-500); */
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 32px;
}

.about__content-container {
    display: flex;
    flex-direction: column;
    gap: 32px;
    width: 480px;
}

.about__header-container {
    display: flex;
    align-items: flex-end;
    width: 100%;
    min-height: 5rem;
}

.about__header-text {
    font-family: 'consola';
    font-size: 3.5rem;
}

.about__portrait {
    max-height: 15rem;
    border-radius: 100%;
}
