.contact {
    overflow: hidden;
    position: relative;
    scroll-snap-align: start;
    position: relative;
    min-height: calc(100vh - 160px);
    display: flex;
    align-items: center;
    justify-content: center;
}

.contact-container {
    /* border: 1px solid var(--base-500); */
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 32px;
}

.contact__form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;
    width: 480px;
}

.contact__row {
    display: flex;
    align-items: center;
    gap: 16px;
    width: 100%;
}

.contact__input {
    padding: 16px;
    font-size: 16px;
    color: var(--text-300);
    background-color: var(--base-400);
    width: 100%;    
    border: 1px solid var(--shadow-500);
    border-radius: 8px;
}

.contact__input:focus {
    outline: none;
    border: 1px solid var(--accent-500);
    color: var(--accent-500);
}

.contact__message {
    font-family: 'Inter', -apple-system, system-ui, sans-serif;
}

.contact__button {
    border: none;
    border-radius: 32px;
    color: var(--accent-500);
    /* background-color: var(--accent-500); */
    border: 1px solid var(--accent-500);
    background-color: var(--base-300);
    font-size: 16px;
    font-weight: bold;
    font-family: 'Inter', -apple-system, system-ui, sans-serif;
    padding: 1rem;
    width: 100%;
    margin: 0 auto;
    transition: all .25s;
}

.contact__button:hover {
    background-color: var(--accent-500);
    color: var(--base-300);
}

.contact__input-container {
    display: flex;
    gap: 1rem;
}
