
.footer {
    height: 160px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: .8rem;
    gap: 32px;
    width: 100%;
    bottom: 0;
    /* position: absolute; */
    background-color: var(--base-800);
}

.footer__socials {
    display: flex;
    gap: 1rem;
}
