.home {
    overflow: hidden;
    position: relative;
    z-index: 1;
    scroll-snap-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 80px);
    background-color: var(--base-800);
    /* background-color: var(--base-400); */
    /* background: url('../assets/home.png'); */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.home__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 1rem;
}

.home__content h1 {
    font-size: 5rem;
    color: var(--base-300);
}

.home__content h3 {
    font-family: 'Comfortaa';
    font-size: 2rem;
    color: var(--base-400);
}

.home__button-container {
    display: flex;
    gap: 32px;
    margin-top: 3rem;
}

.home__button {
    font-size: 1.25rem;
    font-family: "consola";
    border: 1px solid var(--base-300);
    color: var(--base-400);
    border-radius: 0 16px 0 16px;
    padding: 1rem 2rem;
    width: max-content;
    transition: background-color .5s, color .5s, transform .5s, border .5s;
}

.home__button:hover {
    color: var(--accent-500);
    background-color: var(--base-300);
    transform: translate(0, -10px);
}

.home__button--resume {
    color: var(--accent-500);
    background-color: var(--base-300);
}

.home__video {
    z-index: -1;
    pointer-events: none;
    position: absolute;
    object-fit: cover;
    margin: auto;
    object-position: 50%;
    height: 100%;
    width: 100%;
    transform: scale(1.2);
    filter: brightness(0.75) blur(0);
}