.art {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    scroll-snap-align: center;
    position: relative;
    height: 100vh;
    background-color: var(--base-400);
    border-bottom: 1px solid var(--base-500);
    /* color: var(--accent-300); */
}

.art-container {
    z-index: 2;
    display: flex;
    justify-content: center;
    gap: 32px;
    /* border: 1px solid var(--accent-400);     */
}

.gallery {
    display: grid;
    grid-template-columns: repeat(3, 128px);
    overflow: hidden;
    gap: 12px;
    border: 1px solid var(--base-500);
    padding: 12px;
    border-radius: 0 16px 0 16px;
    max-height: calc(2*12px + 3*128px + 2*12px);
    scroll-snap-type: mandatory;
    width: min-content;
    overflow-y: scroll;
    background-color: var(--base-400);
}

.gallery__item-frame {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    width: 128px;
    height: 128px;
    cursor: pointer;
    border-radius: 0 8px 0 8px;
}

.gallery__item-frame:hover {
    outline: 4px solid var(--accent-500);
}

.gallery__item-frame--selected {
    outline: 4px solid var(--accent-500);
}

.gallery__item-frame:hover .gallery__item {
    transform: scale(1.1);
}

.gallery__item-number {
    position: absolute;
    top: 8px;
    left: 8px;
    color: var(--base-300);
    font-family: "Archivo Black", sans-serif;
}

.gallery__item {
    scroll-snap-align: center;
    height: 256px;
    object-position: center;
    object-fit: cover;
    transition: all .25s;
}

.showcase {
    display: flex;
    flex-direction: column;
    max-width: 1024px;
    height: calc(100vh - 128px);
    overflow-y: scroll;
    overflow-x: hidden;
    gap: 16px;
    background-color: var(--base-400);
    border: 1px solid var(--base-500);
    padding: 32px;
    border-radius: 16px 0 16px 0;
    align-self: flex-end;
}

.showcase__item {
    object-fit: contain;
    width: 100%;
}

.showcase__type-container {
    display: flex;
    gap: 8px;
    align-items: center;
}

.showcase__type {
    background-color: var(--base-500);
    padding: 8px 12px;
    border-radius: 32px;
}
