.projects {
    overflow: hidden;
    position: relative;
    scroll-snap-align: center;
    position: relative;
    min-height: 100vh;
    background-color: var(--base-800);
    display: flex;
    align-items: center;
    justify-content: center;
}

.projects-container {
    /* border: 1px solid var(--base-700); */
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 32px;
}

.projects__item-container {
    gap: 2rem;
    border-radius: 16px;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(2, 350px);
}

.projects-container::-webkit-scrollbar {
    display: none;
}

.project__items {
    display: flex;
    gap: 1rem;
    align-items: center;
    flex-wrap: wrap;
}

.project__items--tech {
    margin-top: auto;
    padding-top: 2rem;
}

@media only screen and (max-device-width: 1100px) {

    .projects-container {
        grid-template-columns: 350px 350px;
    }

}

@media only screen and (max-device-width: 750px) {

    .projects-container {
        grid-template-columns: 1fr;
        width: max-content;
    }

}

@media only screen and (max-device-width: 400px) {

    .projects-container {
        grid-template-columns: 1fr;
        width: 100%;
    }
    
}
