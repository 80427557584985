.app {
  position: relative;
  display: flex;
  flex-direction: column;
}

.home__button {
  font-size: 1.25rem;
  font-family: "consola";
  border: 1px solid var(--base-300);
  color: var(--base-400);
  border-radius: 2rem;
  padding: 1rem 2rem;
  width: max-content;
  transition: background-color .5s, color .5s, transform .5s, border .5s;
}

.home__button:hover {
  color: var(--accent-500);
  background-color: var(--base-300);
  transform: translate(0, -10px);
}

.home__button--alt {
  color: var(--accent-500) !important;
  background-color: var(--base-300);
}

.waiting-pointer {
  margin: auto auto 0 0;
  width: .6rem;
  height: .25rem;
  opacity: 0;
  background-color: var(--base-400);
  animation-name: waiting;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}

.waiting-pointer--about {
  background-color: var(--text-500);
  height: .5rem;
  width: 1rem;
}

.section__impact-text {
  z-index: -1;
  /* position: absolute; */
  white-space: nowrap;
  font-size: 160px;
  color: rgb(39, 121, 189);
  font-family: "Archivo Black", sans-serif;
  line-height: 160px;
}

.banner {
  background-color: var(--accent-500);
  color: var(--base-500);
  font-size: 32px;
  width: 150%;
  display: flex;
  align-items: center;
  overflow: hidden;
  font-family: "Archivo Black", sans-serif;
  animation: scroll-right 10s linear infinite;
}

@keyframes scroll-right {
  0% {
      transform: translateX(0);
  }

  100% {
      transform: translateX(-25%);
  }
}
