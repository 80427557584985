.project-card {
    z-index: 2;
    position: relative;
    max-width: 350px;
    min-height: 350px;
    border-radius: 0 16px 0 16px;
    padding: 2rem;
    gap: 1rem;
    display: flex;
    flex-direction: column;
    text-align: inherit;
    font-family: inherit;
    color: inherit;
    font-size: inherit;
    background-color: var(--base-800);
    border: 1px solid var(--base-700);
    transition: transform .25s, box-shadow .25s;
}

.project-card:hover {
    transform: translate(0, -1rem);
    box-shadow: 0 1rem 2rem var(--shadow-900);
    outline: 4px solid var(--accent-400);
}

.project-card h3 {
    color: var(--text-200);
}

.project-card__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-height: 3rem;
}

.project-card__content {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 2rem;
}
