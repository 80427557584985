.navbar {
    z-index: 3;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 160px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 64px;
    padding: 16px 80px;
    pointer-events: none;
}